<template>
  <div class="container">
    <div class="area-content mb8 bgf">
      <div class="fill-content bgf pbs">
        <div class="area-content pt12 pb12 pl15 pr15">
          <CustomerTitle class="mb16" title="基本信息" />
          <div
            v-for="(item, index) in reacData.userInfo"
            :key="index"
            class="centent-cell flex flex-align-center txt-line-1 mb12"
          >
            <span class="label flex-s0">{{ item.label }}:</span>
            <span>{{ item.content || '--' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="area-content bgf pbs"
      style="min-height: 60vh"
      v-if="props.shopType"
    >
      <van-tabs
        class="order-statu-tabs"
        v-model="reacData.selectedStatu"
        @click="onSelectStatu"
      >
        <van-tab v-for="(statu, index) in reacData.orderList" :key="statu.key">
          <template #title>
            <div
              class="order-statu-tab flex flex-align-center relative"
              :class="{
                'txt-blue': statu.key === reacData.selectedStatu
              }"
              :style="{
                marginRight: index === reacData.orderList.length - 1 ? '3px' : 0
              }"
            >
              {{ statu.value }}
              <div
                v-if="statu.key === reacData.selectedStatu"
                class="active-border"
              />
            </div>
          </template>
          <van-list
            v-model:loading="statu.loading"
            :finished="statu.isFinished"
            :immediate-check="false"
            @load="getOrderList"
          >
            <template #finished>
              <div
                class="finished-content flex flex-justify-center flex-align-center"
                :class="{
                  'empty-list': !statu.list?.length
                }"
              >
                {{ statu.list?.length ? '没有更多了' : '暂无订单信息' }}
              </div>
            </template>
            <div
              v-for="orderItem in statu.list"
              :key="orderItem.id"
              class="order-item-content pt12 pl15 pr15"
            >
              <div v-if="statu.key === 'store'" class="border-bottom pb12">
                <div class="flex flex-align-center">
                  <div class="flex-1 overflow-ellipsis">
                    <span class="label color-opacity-65">子订单编号:</span>
                    {{ orderItem.sub_order_no }}
                  </div>
                  <div class="flex-s0">
                    <span
                      class="order-statu"
                      v-if="orderItem.order_status === 'no_use'"
                    >
                      未使用
                    </span>
                    <span
                      class="finish-color"
                      v-if="orderItem.order_status === 'use'"
                    >
                      已使用
                    </span>
                    <span
                      class="finish-color"
                      v-if="orderItem.order_status === 'expire'"
                    >
                      已过期
                    </span>
                  </div>
                </div>
                <div class="order-time fs12">
                  <span v-if="orderItem.order_status === 'no_use'">
                    下单时间：{{ orderItem.order_time }}
                  </span>
                  <span v-if="orderItem.order_status === 'use'">
                    使用时间：{{ orderItem.order_used_time }}
                  </span>
                  <span v-if="orderItem.order_status === 'expire'">
                    过期时间：{{ orderItem.order_use_end_time }}
                  </span>
                </div>
                <div
                  class="goods-content border-bottom flex flex-align-center pt12 pb12"
                >
                  <img
                    class="goods-image mr8 flex-s0"
                    :src="orderItem.goods_img"
                  />
                  <div class="goods-detail-line fw500" style="height: 70px">
                    <div
                      class="flex flex-justify-between flex-direction-column"
                      style="height: 100%"
                    >
                      <div
                        class="overflow-ellipsis flex-s0"
                        :class="[
                          orderItem.order_status === 'use' ||
                          orderItem.order_status === 'expire'
                            ? 'finish-color'
                            : ''
                        ]"
                      >
                        {{ orderItem.goods_tiltle }}
                      </div>
                      <div class="flex">
                        <div class="effect-box fs12 fw300 mt8 inline-block">
                          <div
                            class="effect-text overflow-ellipsis"
                            :class="[
                              orderItem.order_status === 'use' ||
                              orderItem.order_status === 'expire'
                                ? 'finish-color'
                                : ''
                            ]"
                          >
                            {{ orderItem.product_sku_name }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="order-time fs12 overflow-ellipsis"
                        v-if="orderItem.order_status !== 'expire'"
                      >
                        <span v-if="orderItem.order_status === 'no_use'">
                          有效期：{{ orderItem.order_use_start_time || '--' }}
                          至
                          {{ orderItem.order_use_end_time || '--' }}
                        </span>
                        <span v-if="orderItem.order_status === 'use'">
                          核销人：{{ orderItem.order_used_name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-reverse">
                  <div class="mt12">
                    <span class="color-opacity-65">实收金额：</span>
                    <span class="order-statu fw600 fs14">
                      ￥{{ orderItem.original_amount }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="statu.key === 'express'" class="border-bottom pb12">
                <div class="flex flex-align-center">
                  <div class="flex-1 overflow-ellipsis">
                    <span class="label color-opacity-65">子订单编号:</span>
                    {{ orderItem.sub_order_no }}
                  </div>
                  <div class="flex-s0">
                    <span
                      class="order-statu"
                      v-if="
                        orderItem.logistics_status == '0' &&
                        orderItem.order_status != 'refund'
                      "
                    >
                      待发货
                    </span>
                    <span
                      class="order-statu"
                      v-if="
                        orderItem.logistics_status == '1' &&
                        orderItem.order_status != 'refund'
                      "
                    >
                      已发货
                    </span>
                    <span
                      class="order-statu"
                      v-if="
                        orderItem.logistics_status == '3' &&
                        orderItem.order_status != 'refund'
                      "
                    >
                      已完成
                    </span>
                    <span
                      class="order-statu"
                      v-if="orderItem.order_status == 'refund'"
                    >
                      全退款
                    </span>
                  </div>
                </div>
                <div class="order-time fs12">
                  下单时间：{{ orderItem.order_time }}
                </div>
                <div
                  class="goods-content border-bottom flex flex-align-center pt12 pb12"
                  v-for="(goods, index) in orderItem.goods_list"
                  :key="index"
                >
                  <img
                    class="goods-image flex-s0 flex-g0"
                    :src="goods.goods_img"
                  />
                  <div class="flex-s1 flex-g1">
                    <div
                      class="goods-detail-line flex flex-direction-column flex-justify-between fw500"
                      style="height: 70px"
                    >
                      <div class="overflow-ellipsis ml8">
                        {{ goods.goods_tiltle }}
                      </div>
                      <div class="flex">
                        <div class="effect-box fs12 fw300 mt8 inline-block ml8">
                          <div class="effect-text overflow-ellipsis">
                            {{ goods.product_sku_name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-s0 flex-g0">
                    <div>
                      <span class="goods-sum-icon">¥</span>
                      <span class="fw600">{{ Number(goods.goods_price) }}</span>
                    </div>
                    <div class="flex-reverse color-opacity-65">
                      *{{ goods.num }}
                    </div>
                  </div>
                </div>
                <div class="flex-reverse">
                  <div class="mt12">
                    <span class="color-opacity-65">实收金额：</span>
                    <span class="order-statu fw600 fs14">
                      ￥{{ orderItem.original_amount }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="statu.key === 'coupon'">
                <div @click="openInstuctions(orderItem)">
                  <div class="coupon-title-box tc">
                    <span class="coupon-title-date fs12 fw400">
                      可用时间: {{ orderItem.use_start_time }} 至
                      {{ orderItem.use_end_time }}
                    </span>
                  </div>
                  <div class="coupon-content-box">
                    <div class="circular-left" />
                    <div class="circular-right" />
                    <div class="flex pt16 pl16">
                      <div class="price-box tc cl00 mr24">
                        <div>
                          <span class="fs12">￥</span>
                          <span class="fs28 fw600">
                            {{ Number(orderItem.coupon_amount) }}
                          </span>
                        </div>
                        <div
                          class="fs12 fw400 cl00"
                          v-if="Number(orderItem.coupon_amount_limit)"
                        >
                          满{{ Number(orderItem.coupon_amount_limit) }}可用
                        </div>
                        <div class="fs12 fw400 cl00" v-else>无门槛</div>
                      </div>
                      <div class="date-box">
                        <div class="cl00 fw600 fs14">
                          {{ orderItem.coupon_name }}
                        </div>
                        <div class="cl8c mt4 fs10">
                          {{ orderItem.expires_time }} 到期
                        </div>
                      </div>
                    </div>
                    <div
                      class="bdtd flex flex-align-center flex-justify-between pl16 pr16 pt12 mt16"
                    >
                      <div class="fs12 fw400 cl8c">使用说明</div>
                      <div class="">
                        <van-icon name="arrow-down" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="orderItem.showMore"
                    class="coupon-instructions-box p12"
                  >
                    <div class="flex flex-align-center">
                      <div>领券时间：</div>
                      <div>{{ orderItem.create_time }}</div>
                    </div>
                    <div class="mt12">
                      <div>使用说明：</div>
                      <div class="mt12">{{ orderItem.coupon_desc }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch, ref } from 'vue'
import CustomerTitle from '@/components/customerTitle/index.vue'
import {
  getDetailUserInfo,
  getBusinessStoreOrderList,
  getBusinessExpressOrderList,
  getBusinessCouponList
} from '@/service/customer.service.js'
import { defaultAvatar, sourceEnum, clientStatuEnum } from '@/utils/constants'
import { orderType } from '../portrait'

const props = defineProps({
  // 企微类型主体信息
  dataSource: {
    type: Object,
    default: () => ({})
  },
  visible: {
    type: Boolean,
    default: () => false
  },
  // 是否是商城业务主体
  shopType: {
    type: Boolean,
    default: () => false
  }
})
const reacData = reactive({
  pageSize: 10,
  userInfo: {}, // 客户详情
  orderList: orderType, // 订单列表
  selectedStatu: 'store' // 当前选中的订单状态
})

onMounted(() => {
  getUserInfo()
  if (props.shopType) {
    onSelectStatu(0)
  }
})

// 优惠券说明展开判断
const openInstuctions = (obj) => {
  if (obj.showMore) {
    obj.showMore = !obj.showMore
  } else {
    obj.showMore = true
  }
}

// 切换订单状态
const onSelectStatu = (index) => {
  const { orderList } = reacData
  reacData.selectedStatu = orderType[index]['key']
  if (!orderList[index].list) {
    getOrderList()
  }
}
// 获取客户详情
const getUserInfo = async () => {
  try {
    const { subject_data_id } = props.dataSource
    const res = await getDetailUserInfo(subject_data_id)

    if (res.code === 200) {
      reacData.userInfo = res.data.info
    }
  } catch (error) {
    console.log(error)
  }
}
// 获取订单信息
const getOrderList = async () => {
  try {
    const { subject_data_id, yw_id } = props.dataSource
    const { pageSize, orderList, selectedStatu } = reacData
    let currentPage = 0

    const index = orderList.findIndex((item) => item.key === selectedStatu)
    const list = orderList[index].list || []

    if (list.length > 0) {
      currentPage = Math.ceil(list.length / pageSize)
    }

    const body = {
      yw_id,
      page_size: pageSize,
      page: currentPage + 1
    }
    if (reacData.selectedStatu === 'store') {
      const { data } = await getBusinessStoreOrderList(body)
      orderList[index].loading = false

      if (data.list.length > 0) {
        orderList[index].list = [...list, ...data.list]
      } else {
        orderList[index].isFinished = true
      }
    }
    if (reacData.selectedStatu === 'express') {
      const { data } = await getBusinessExpressOrderList(body)
      orderList[index].loading = false

      if (data.list.length > 0) {
        orderList[index].list = [...list, ...data.list]
      } else {
        orderList[index].isFinished = true
      }
    }
    if (reacData.selectedStatu === 'coupon') {
      const { data } = await getBusinessCouponList(body)
      orderList[index].loading = false

      if (data.list.length > 0) {
        orderList[index].list = [...list, ...data.list]
      } else {
        orderList[index].isFinished = true
      }
    }
    console.log('1111111', reacData.orderList)
  } catch (error) {
    console.log(error)
  }
}
</script>

<style lang="less" type="text/less" scoped>
.container {
  height: 100%;
  overflow: scroll;
}
.active-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(23, 115, 250, 0) 0%,
    #1773fa 48%,
    rgba(63, 181, 255, 0) 100%
  );
}
.border-bottom {
  border-bottom: 1px solid #edeef2;
}
.order-statu-tabs {
  :deep(.van-tab) {
    flex: 1;
    // padding: 0 12px;
    height: 51px;
    font-weight: 400 !important;
    color: #171717 !important;

    .van-tab__text {
      height: 100%;
    }
  }
  :deep(.van-tabs__content) {
    height: auto !important;
    overflow: initial;
  }

  .finished-content {
    height: 50px;
  }
  .empty-list {
    height: 100px;
  }
  .order-statu-tab {
    height: 100%;
  }
  .order-statu-tab:first-child {
    margin-left: 3px;
  }
  .order-item-content {
    .order-time {
      //   margin-top: 6px;
      color: rgba(23, 23, 23, 0.4);
    }
    .color-opacity-65 {
      opacity: 0.65;
    }
    .label {
      margin-right: 4px;
    }
    .order-statu {
      color: #ff3b30;
    }
    .finish-color {
      color: #8c8c8c;
    }
  }
  .order-item-content:last-child {
    border: none;
  }
  .goods-content {
    .goods-image {
      width: 70px;
      height: 70px;
    }
    .goods-detail-line {
      margin-bottom: 4px;
    }
    .goods-sum-icon {
      margin-right: 1px;
    }
  }
}
.effect-box {
  // display: inline-block;
  height: 24px;
  background: #f5f5f5;
  .effect-text {
    color: #000000;
    line-height: 24px;
  }
}
.coupon-title-box {
  width: 100%;
  height: 32px;
  background: #fafafa;
  .coupon-title-date {
    line-height: 32px;
    color: #8c8c8c;
  }
}
.coupon-content-box {
  position: relative;
  width: 100%;
  height: 119px;
  background: #f5f5f5;
  .price-box {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -12px;
      top: 9px;
      width: 1px;
      height: 32px;
      background: #d9d9d9;
    }
  }
  .circular-left {
    position: absolute;
    left: -8px;
    top: 72px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
  }
  .circular-right {
    position: absolute;
    left: calc(100vw - 37px);
    top: 72px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
  }
}
.coupon-instructions-box {
  width: 100%;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
}
</style>
